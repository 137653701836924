import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import ReactGA from "react-ga4";
import Homepage from "./Screen/Homepage/Homepage";
import Privacy from "./Components/Privacypolicy/Privacy";
import Terms from "./Components/TermsOfService/TermService";
import { useEffect } from "react";

const TRACKING_ID = "G-525717Z3MV"
ReactGA.initialize(TRACKING_ID);
function App() {
useEffect(()=>{
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home page" });
},[])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/service" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
