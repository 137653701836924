import React, { useRef, useState } from 'react';

import emailjs from '@emailjs/browser';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import './header.css';
// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { langList } from '../../../Data/Data';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
const tomorrow = dayjs().add(1, 'day');
function Header(props) {

    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dataForm, setDataForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        job: '',
        time: '10:00',
        date: tomorrow,
        product: [],

    })
    const [langChoose, setLangchoose] = useState({
        img: langList.find((v) => v.id === localStorage.getItem('i18nextLng')) ? langList.find((v) => v.id === localStorage.getItem('i18nextLng')).img : './img/american.png',
        name: langList.find((v) => v.id === localStorage.getItem('i18nextLng')) ? langList.find((v) => v.id === localStorage.getItem('i18nextLng')).name : 'ENG'
    })
    const form = useRef();

    function validateEmail(email) {
        // Biểu thức chính quy để kiểm tra địa chỉ email
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Sử dụng test() để kiểm tra email với biểu thức chính quy
        return emailRegex.test(email);
    }
    const sendEmail = (e) => {
        e.preventDefault();
        if (!dataForm.firstName) {
            toast.error(t('firstNameisnotnull'));
            return
        }
        if (dataForm.email && !validateEmail(dataForm.email)) {
            toast.error(t('emailisinvalid'));
            return
        }
        if (!dataForm.email || !validateEmail(dataForm.email)) {
            toast.error(t('emailisnotnull'));
            return
        }
        if (!dataForm.phone) {
            toast.error(t('phoneisnotnull'));
            return
        }
        if (dataForm.product.length === 0) {
            toast.error(t('productNameisnotnull'));
            return
        }
        emailjs.sendForm('service_r75p4uv', 'template_nby7l1d', form.current, 'HofqcUNIfufx0I9V6')
            .then((result) => {
                toast.success(t('registerdemosucessfull'));
            }).catch((error) => {
                toast.error(error.text);

            });
    }

    const handleChangeInput = (value, key) => {
        const data = { ...dataForm, [key]: value }
        setDataForm(data)
    }

    const changeLangguage = (id, img, name) => {
        i18n.changeLanguage(id)
        setLangchoose({
            img,
            name
        })
    }

    return (

        <header>
            <div className="infoHeaderMenu-wraper">
                <div className='infoHeaderMenu container'>
                    <div className="infoleftmenu">
                        <div className="redbug"></div>
                        <div className="titlebug">{t('yourVisionOurExpertise')}</div>
                    </div>
                    <div className="inforightmenu">
                        <div className="mailmenu"><a href="mailto:hr@digitechs.vn">{t('email')}: contact@digitechs.vn</a></div>
                        <div className="phonemenu"><a href="tel:02439989866">{t('tel')}: 0243 998 9866</a></div>
                    </div>
                </div>
            </div>
            <div className="headerMenu container">
                <div className='row'>
                    <div className="menuLogo ">
                        <img src="./img/newlogo.png" alt="" />
                        <Navbar expand="lg" className="logoHeader">
                            <div>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto logoUl" >
                                        {!!props.data &&
                                            props.data.map((data, index) => {
                                                return (
                                                    <Nav.Link key={index} className="sparkle u-hover--sparkle" href={`#${data.href}`}>{t(data.title)}</Nav.Link>
                                                );
                                            })}
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </Navbar>
                    </div>
                </div>
                <div className='row'>

                </div>
                <div className="menuBtn">
                    <Dropdown>
                        <Dropdown.Toggle className='dropdown-choose-lang' id="dropdown-basic">
                            <img src={langChoose.img} alt="" className="american" />
                            {langChoose.name}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                langList.map((v) => (
                                    <Dropdown.Item key={v.id} onClick={() => changeLangguage(v.id, v.img, v.name)} > <img src={v.img} alt="" className="american" /> {v.desc}</Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <div>
                        <>
                            <Button variant="primary" className='btn-bfr demo-button' onClick={handleShow}>{t('bookADemo')}</Button>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t('bookADemo')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form className='fidd' ref={form} onSubmit={sendEmail}>
                                        <div className='fidAll'>
                                            <div className='firstName'><p>{t('firstName')}<span style={{ color: 'red' }}>*</span></p><input required value={dataForm.firstName} onChange={(e) => handleChangeInput(e.target.value, 'firstName')} type="text" name='from_firstName' placeholder={t('enterFirstName')} /></div>
                                            <div className='lastName' ><p>{t('lastName')}</p><input value={dataForm.lastName} onChange={(e) => handleChangeInput(e.target.value, 'lastName')} type="text" name='from_lastName' placeholder={t('enterLastName')} /></div>
                                        </div>
                                        <div className='fidAll2'>
                                            <div className='Email' ><p>{t('email')} <span style={{ color: 'red' }}>*</span></p><input required value={dataForm.email} onChange={(e) => handleChangeInput(e.target.value, 'email')} type="email" name='from_email' placeholder={t('enterEmail')} /></div>
                                            <div className='phoneNumber' ><p>{t('phoneNumber')} <span style={{ color: 'red' }}>*</span></p><input required value={dataForm.phone} onChange={(e) => handleChangeInput(e.target.value, 'phone')} name='number' type="phone" placeholder={t('enterPhoneNumber')} /></div>
                                        </div>
                                        <div className='fidAll3'>
                                            <div className='companyName' ><p>{t('companyName')}</p><input value={dataForm.company} onChange={(e) => handleChangeInput(e.target.value, 'company')} type="text" name='company' placeholder={t('enterCompanyName')} /></div>
                                            <div className='jobPosition' ><p>{t('jobPosition')}</p><input value={dataForm.job} onChange={(e) => handleChangeInput(e.target.value, 'job')} type="text" name='job' placeholder={t('enterYourJobPosition')} /></div>
                                        </div>
                                        <div className='fidAll4'>
                                            <div className='Time' ><p>{t('time')} <span style={{ color: 'red' }}>*</span></p><input required value={dataForm.time} onChange={(e) => handleChangeInput(e.target.value, 'time')} name='time' type="time" placeholder='' /></div>

                                            <div className='Date' ><p>{t('date')} <span style={{ color: 'red' }}>*</span></p> <LocalizationProvider className='date-picker-mui' dateAdapter={AdapterDayjs}>
                                                <DatePicker slotProps={{ textField: { name: 'date' } }} name format="DD/MM/YYYY" value={dataForm.date} onChange={(newValue) => handleChangeInput(newValue, 'date')} />
                                            </LocalizationProvider></div>

                                        </div>
                                        <div className='fidAll6'>
                                            <p>{t('product')} <span style={{ color: 'red' }}>*</span></p>
                                            {/* <DropdownMultiselect selected={dataForm.product} handleOnChange={(selected) => handleChangeInput(selected, 'product')} options={props.select} name="product" placeholder={t('selectProduct')} /> */}

                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                multiple
                                                value={dataForm.product}
                                                onChange={(e) => handleChangeInput(e.target.value, 'product')}
                                                name='product'
                                            >
                                                {props.select?.map((product) => (
                                                    <MenuItem
                                                        key={product.key}
                                                        value={product.label}
                                                    >
                                                        {t(product.label)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='fidAll5'>
                                            <div className='btnRegister' ><input onClick={(e) => sendEmail(e)} type="submit" value={t('register')} placeholder='' /></div>
                                        </div>
                                        <ToastContainer position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false} />
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </>
                    </div>
                </div>
            </div>

            <div className="headerSlider">
                <>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {!!props.til &&
                            props.til.map((data, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className='sliderSwiper'>
                                            <img src={`./img/${data.img}`} alt="" />
                                            <div className='captionBox '>
                                                <div className="titleCaption">{t(data.title)}</div>
                                                <p>{t(data.info1)}</p>
                                                <p>{t(data.info2)}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </>
            </div>
        </header>
    );
}

export default Header;




