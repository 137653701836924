import React from "react";
import { menu, select, firstrow, secondrow, thridrow } from '../../Data/Data'

import Header from "../Homepage/Header/Header";
import Footer from "../Homepage/Footer/Footer";
import './Priavacy.css'

const Privacy = () => {
    return (
        <>
            <Header data={menu} select={select} />
            <div style={{
                maxWidth: "70rem", marginTop: "50px", marginLeft: "auto",
                marginRight: "auto" ,  marginBottom: "50px"
            }}>
                <strong>
                    Privacy Policy
                </strong>
                <br />
                DTS LLC - This privacy policy has been issued to describe how DTS protects, collects, and uses the personal information the customer provides using our website and our products.
                <br />
                <strong>
                    This Privacy Policy applies to you if
                </strong>
                <br />

                - interact with any of DTS websites (contact@digitechs.vn) or our social media pages (collectively, the "Sites") ("website users");
                - use DTS's communication and messaging products, customer workspaces, mobile applications, and our other applications and services (collectively, the "DTS Services") ("customers");
                - interact with any of DTS's end user messenger domains APIs  ("end-users");
                - are a marketing prospect, who is anyone whose data DTS processes for the purposes of assessing customer eligibility ("marketing prospect"); or
                - receive marketing communications from DTS. <br />
                <strong>
                    Information we collect
                </strong>
                <br />
                We may collect the following personal data about:
                <br />
                - our website users
                <br />
                - recipients of marketing communications
                <br />
                - marketing prospects
                <br />
                <strong>Registration, contact, and company information:</strong>
                <br />
                - first and last names
                <br />
                - email addresses
                <br />
                - phone numbers
                <br />
                - avatars
                <br />
                - company name;
                <br />
                - your role in your company.
                <br />
                <strong>Payment information:</strong>
                <br />
                - credit card information;
                <br />
                - billing and mailing addresses;
                <br />
                - other payment-related information.
                <br />
                <strong>Device data:</strong>
                <br />
                - operating system type and version number, manufacturer and model;
                <br />
                - browser type;
                <br />
                - screen resolution; <br />
                - IP address; <br />
                - unique device identifiers. <br />
                <strong>Service data:</strong> <br />
                - the website you visited before browsing to the services;
                <br />
                - how long you spent on a page or screen;
                <br />
                - navigation paths between pages or screens;
                <br />
                - session date and time; activity status (including first seen, last seen, last heard from - and last contacted);
                <br />
                - pages viewed;
                <br />
                - links clicked;
                <br />
                - language preferences
                <br />
                - tags applied within customer accounts
                <br />
                - DTS assigned user identifier.
                <br />
                <strong>Third-party source data</strong> <br />
                - profile information gathered from social networking sites;
                <br />
                - information that you have viewed or interacted with our content;
                <br />
                - company information;
                <br />
                - job titles;
                <br />
                - avatars;
                <br />
                - email addresses;
                <br />
                - phone numbers;
                <br />
                - addresses;
                <br />
                - geolocation data.
                <br />
                <strong>The sources of this third party personal data may include:</strong> <br />
                - Contact enrichment and lead generation providers; and <br />
                - Targeted online advertising providers <br />
                <strong>We may collect the following personal data about our customers and end-users (to the extent applicable):</strong>
                <br />
                <strong>Registration and contact information:</strong> <br />
                - first and last names <br />
                - email addresses <br />
                - phone numbers <br />
                - avatars <br />
                - company name; <br />
                - your role in your company. <br />
                <strong>Payment information:</strong>
                <br />
                - credit card information; <br />
                - billing and mailing addresses; <br />
                - other payment-related information. <br />
                <strong>Device data:</strong>
                <br />
                - operating system type and version number, manufacturer and model; <br />
                - browser type; <br />
                - screen resolution; <br />
                - IP address; <br />
                - unique device identifiers. <br />
                <strong>Service data:</strong>
                <br />
                - the website you visited before browsing to the services;
                <br />
                - how long you spent on a page or screen;
                <br />

                - navigation paths between pages or screens;
                <br />

                - session date and time; activity status (including first seen, last seen, last heard from - and last contacted);
                <br />

                - pages viewed;
                <br />

                - links clicked;
                <br />

                - language preferences
                <br />

                - tags applied within customer accounts
                <br />

                - DTS assigned user identifier.
                <br />

                <strong>Third-party source data</strong>
                <br />
                - profile information gathered from social networking sites;
                <br />
                - information that you have viewed or interacted with our content;
                <br />

                - company information;
                <br />

                - job titles;
                <br />

                - avatars;
                <br />

                - email addresses;
                <br />

                - phone numbers;
                <br />

                - addresses;
                <br />

                - geolocation data.
                <br />

                <strong>Use of the information we collect</strong>
                <br />

                We collect and process your personal data for the following purposes <br />

                - Providing and facilitating delivery of the DTS Services and Sites: We process your personal data to perform our contract with you for use of our Services and Sites and to fulfill our obligations under applicable terms of service. Where we have not entered into a contract with you, we process your personal data in reliance on our legitimate interests to operate and administer the DTS Services and Sites. For example, to create, administer and manage your account. <br />
                - Communicating with you about the DTS Services and providing customer support: We may send you service, technical and other administrative messages in reliance on our legitimate interests in administering the DTS Services. For example, we may send you messages about the availability or security of the DTS Services. We also process your personal data to respond to your comments and questions and to provide customer care and support. <br />

                - Improving the DTS Services and Sites: We process your personal data to improve and optimize the DTS Services and Sites and to understand how you use the DTS Services and Sites, including to monitor usage or traffic patterns and to analyze trends and develop new products, services, features and functionality in reliance on our legitimate interests. <br />
                - Sending marketing communications: We process your personal data to send you marketing communications via email, post or SMS about our products, services, and upcoming events that might interest you in reliance on our legitimate interests or where we seek your consent. Please see the "Your Privacy Rights and Choices" section below to learn how you can control your marketing preferences. <br />
                - Managing event registrations and attendance: We process your personal data to plan and host events for which you have registered or that you attend, including sending related communications to you. <br />
                - Maintaining the security of the DTS Services and Sites: We process your personal data to control unauthorized use or abuse of the DTS Services and Sites, or otherwise detect, investigate or prevent activities that may violate DTS policies or applicable laws, in reliance on our legitimate interests to maintain and promote the safety and security of the DTS Sites and Services. <br />
                - Displaying personalized advertisements: We process your personal data to advertise to you and to provide personalized information, including by serving and managing advertisements on our Sites and on third party sites, in reliance on our legitimate interests to support our marketing activities and advertise our products and services or, where necessary, to the extent you have provided your consent. <br />
                - Carrying out other legitimate business purposes: including invoicing, audits, fraud monitoring and prevention. <br />
                - Complying with legal obligations: We process your personal data when cooperating or complying with public and government authorities, courts or regulators in accordance with our obligations under applicable laws and to protect against imminent harm to our rights, property or safety, or that of our users or the public, as required or permitted by law. <br />
                <strong>Changes</strong> <br />
                - DTS reserves the right to make changes to this Privacy Policy at any time. Any changes done to the privacy will be reflected by posting a new version online. We encourage you occasionally to check this page. If we make any changes we will notify you by email (to be sent to the email address that you have provided in your account), or by sending you notification on the platform. <br />
                <strong>Data Retention</strong> <br />
                - DTS will retain your information for as long as your account is active or as needed. If you wish that we no longer use your information to provide your services please contact us at contact@digitechs.vn .We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. <br />
                - If your account become inactive, we will delete your account data after 24 months. <br />
                <strong>Cookies</strong> <br />
                - Cookies are small amounts of information which we store on your computer for purposes of storing information about a user's preferences, Like many other websites, our platform employs cookies to deliver content specific to your interests, Cookies make it easier for you to log on to and use the Website during future visits, user can set his or her browser to refuse cookies although, in that case, you may not be able to use certain features on our Website. <br />

                <strong>Security / Confidentiality</strong> <br />
                - All your personal information will be protected by reasonable security safeguards against unlawful processing, accidental loss, destruction or damage or theft, as well as unauthorized access, disclosure, copying, use or modification, Though It is your responsibility to protect your personal data and maintain the confidentiality of your user information and password. You are also responsible for immediately notifying DTS of any unauthorized use of your account, or breach of your account information or password. DTS will not be liable for any loss that you may incur as a result of someone else using your username or password, either with or without your knowledge. You shall be liable for any expenses, including usage charges and fines, fees, civil judgments, and reasonable attorney's fees for your failure to promptly notify of unauthorized use of your account or breach of your account information or password. Despite all the precautions we take, we cannot ensure or warrant the security of information you transmit to DTS as we don't have a full control on the Internet environment, you acknowledge that you provide such information at your own risk. There is no guarantee that information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. Finally, if our business enters into a joint venture with or is sold to or merged with another business entity, your information may be disclosed to our new business partners or owners.
                <br />
                <strong>Ask Us about Your Privacy</strong>
                <br />
                - If you have any questions about this Privacy, Please contact us at contact@digitechs.vn
                <br />

                Last date updated:  25/July/2024
                <br />
                <strong>Features</strong>
                <br />
                WhatsApp Marketing automation <br />
                Whatsapp CRM & Customer Service <br />
                Whatsapp Chatbot <br />
                Whatsapp Multi-agent Inbox  <br />
                WhatsApp campaigns <br />
                Abandoned cart recovery <br />
                Customer win-back flow <br />
                Post-purchase upsell flow <br />

                <strong>Welcome discount popup</strong>
                <br />
                Integrations <br />
                For Shopify stores <br />
                For Salla stores <br />
                For Zid stores <br />
                <strong>Comparison </strong>
                <br />
                Vs Email Marketing <br />
                Vs Mailchimp <br />
                Vs Klaviyo <br />
                Vs Omnisend <br />
                Vs Wati <br />
                Vs Zoko <br />
                <strong>Free Tools & Resources</strong>
                <br />
                Cart abandonment Free App <br />
                WhatsApp link generator <br />
                WhatsApp Pricing Calculator <br />
                Whatsapp campaign Templates <br />
                Marketing automation Templates <br />
                Customer service Templates <br />
                Ebooks & Ultimate guides <br />
                <strong>Company</strong>
                <br />
                Help center <br />
                Privacy policy <br />
                Terms of service <br />
            </div>
            <Footer data={firstrow} data2={secondrow} data3={thridrow} />
        </>
    )
}

export default Privacy

