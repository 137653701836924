import React from 'react'
import Header from '../../Components/Homepage/Header/Header'
import Introduce from '../../Components/Homepage/Introduce/Introduce'
import Service from '../../Components/Homepage/Service/Service'
import SliderLogo from '../../Components/Homepage/SliderLogo/SliderLogo'
import Aboutus from '../../Components/Homepage/Aboutus/Aboutus'
import Office from '../../Components/Homepage/Office/Office'
import Footer from '../../Components/Homepage/Footer/Footer'
import {menu, imgSlider, rightInfo, rightInfoNumber, select, service, sliderlogo, abu, office, firstrow, secondrow, thridrow, service2} from '../../Data/Data'
import Contact from '../../Components/Homepage/Contact/Contact'

function Homepage() {
  return (
    <>  
      <Header data={menu} til={imgSlider} select={select} />
      <Introduce data={rightInfo} number={rightInfoNumber}/>
      <Service data={service} data2={service2}/>
      <SliderLogo data={sliderlogo}/>
      <Aboutus data={abu}/>
      <Office data={office} />
      {/* <Contact /> */}
      <Footer data={firstrow} data2={secondrow} data3={thridrow}/>
    </>
  )
}

export default Homepage;