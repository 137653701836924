import React from "react";
import { menu, select, firstrow, secondrow, thridrow } from '../../Data/Data'

import Header from "../Homepage/Header/Header";
import Footer from "../Homepage/Footer/Footer";

const Terms = () => {
    return (
        <>
            <Header data={menu} select={select} />
            <div style={{maxWidth: "70rem", marginTop: "50px", marginLeft: "auto", marginRight: "auto" ,  marginBottom: "50px"}}>
                <h1>Terms of service</h1>
                <br />
                <strong>Payment methods</strong>
                <br />
                 - Activation and subscription (monthly + annual) fees: required in advance before activation. <br />
                 - Monthly subscription fees are paid using a credit card. <br />
                 - Annual subscription fees are paid using bank transfers. <br />
                 - Monthly usage fees are paid using a credit card. <br />
                <strong>Payment terms</strong> <br />
                 - All the prices shown do not include taxes. <br />
                 - The billing cycle is calculated every first day of the month. <br />
                 - Paying for the annual subscription will get you a discount of 2 a month. <br />
                 - The company is not entitled to refund any amount paid except in the event of a technical malfunction from the part of DTS, and the client is unable to access the platform for a period exceeding 7 working days. <br />
                 - Each 1,000 conversations will be calculated at a value of 40 riyals. For example, when the monthly consumption goes from 1001 to 2000, an amount of 40 riyals will be added to the monthly bill. <br />
                 - Monthly subscription and activation (if found) fees  are paid in advance. <br />
                 - Monthly usage subscriptions (does not include the 1000 free conversations) are paid at the end of the month. <br />
                 - The subscription can be started during any day of the month, and the monthly subscription will be deducted according to the percentage and proportion to the days you used the DTS platform (applicable only for the first month). <br />
                 - The DTS system is disconnected after 7 days from the issuance of any unpaid invoice. <br />
                 - Prices do not include the WhatsApp package, which is directly deducted from the Meta platform. For more details, please refer back to WhatsApp prices available on this link. For example, the price of business-initiated conversations is around 12 halalas, the price of customer-initiated conversations is 7 halalas, and the first 1000 conversations per month are free (the example applies to Saudi numbers only). <br />
                <strong>General Conditions</strong>
                <br />
                - A person responsible for managing the system and distributing permissions must be appointed. <br />
                 - The user of the system is responsible for complying with all the rules and policies of WhatsApp and Meta as described here. <br />
                 - If the chatbot is connected to the company’s systems through the API, the request capacity is one request per second. <br />
                 - The custom chatbot is manually built and modified by the DTS team according to the customer’s needs. <br />
                 - All packages include one phone number. You can add other numbers in exchange for subscription fees. <br />
                 - All customer information will be available once you have an active paid subscription. In addition, all customer conversation history that happened during the past 24 months, will always be available. <br />
                 - Requests and inquiries are answered within one working day. <br />
                 - Work to resolve technical issues affecting service continuity begins immediately. <br />
                <strong>Ask about the terms</strong> <br />

                If you have any questions about our terms of service, Please contact us at contact@digitechs.vn <br />

                Last date updated:  25/July/2024
                <br />
                <strong>Features</strong> <br />
                WhatsApp Marketing automation
                <br />
                Whatsapp CRM & Customer Service
                <br />
                Whatsapp Chatbot
                <br />
                Whatsapp Multi-agent Inbox
                <br />
                WhatsApp campaigns 
                <br />
                Abandoned cart recovery
                <br />
                Customer win-back flow
                <br />
                Post-purchase upsell flow
                <br />
                <strong>Welcome discount popup</strong>
                <br />
                Integrations
                <br />
                For Shopify stores
                <br />
                For Salla stores
                <br />
                For Zid stores
                <br />
                <strong>Comparison</strong>
                <br />
                Vs Email Marketing
                <br />
                Vs Mailchimp
                <br />
                Vs Klaviyo
                <br />
                Vs Omnisend
                <br />
                Vs Wati
                <br />
                Vs Zoko
                <br />
                <strong>Free Tools & Resources</strong>
                <br />
                Cart abandonment Free App
                <br />
                WhatsApp link generator
                <br />
                WhatsApp Pricing Calculator
                <br />
                Whatsapp campaign Templates
                <br />
                Marketing automation Templates
                <br />
                Customer service Templates
                <br />
                Ebooks & Ultimate guides
                <br />
                <strong>Company</strong>
                <br />
                Help center
                <br />

                Privacy policy
                <br />

                Terms of service
                <br />

            </div>
            <Footer data={firstrow} data2={secondrow} data3={thridrow} />
        </>
    )
}

export default Terms