import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './office.css';
import { useTranslation } from 'react-i18next';
function Office(props) {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <div className='ourOffice'>
            <section className='office container'>
                <div className='officeTitle' data-aos="fade-up" data-aos-once='true'>{t('ourOffice')}</div>
                <div className="redline"></div>
                <div className='officeAll'>
                    {!!props.data &&
                        props.data.map((data, index) => {
                            return (
                                <div key={index} className='back' data-aos="fade-up" data-aos-once='true'>
                                    <div className='officeItem'>
                                        <div className='officeIcon'><img src={`./img/${data.icon}`} alt="" /></div>
                                        <div className='officeTitle1'>{data.title}</div>
                                        <p className='officeInfo'>{data.info}</p>
                                        <p className='officeNumber'>{data.number}</p>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </section>
        </div>
    );
}

export default Office;