import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './introduce.css';
import { useTranslation } from 'react-i18next';
function Introduce(props) {
    const { t } = useTranslation();
    const [counterOn, setCounterOn] = useState(false);
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(true)}>
            <div className="introduceCompany wrapper-session" id='introduceCompany'>
                <section className="introduce container" data-aos="fade-up" data-aos-once='true'>
                    <div className="leftIntro">
                        <div className="star">
                            <img src="./img/star.svg" alt="" />
                        </div>
                        <div className="titleIntro">{t('whyweAreTrusted')}</div>
                    </div>
                    <div className="rightIntro">
                        {!!props.data &&
                            props.data.map((data, index) => {
                                return (
                                    <div key={index}>
                                        <div className="titleRightIntro">{t(data.info1)}</div>
                                        <div className="titleRightIntro2">{t(data.info2)}</div>
                                    </div>
                                );
                            })}

                        <div className="evaluate">
                            {!!props.number &&
                                props.number.map((data, index) => {
                                    return (
                                        <div className="evaluate-item" key={index}>
                                            <div className="number">
                                                {counterOn && <CountUp start={0} end={data.number} duration={2} delay={0} />}
                                                <div className={`plus${index}`}>+</div>
                                            </div>
                                            <div className="titleNumber">{t(data.titleNumber)}</div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </section>
            </div>
        </ScrollTrigger>
    );
}

export default Introduce;