import React from 'react';
import './footer.css'
import { useTranslation } from 'react-i18next';

function Footer(props) {
    const { t } = useTranslation();
    return (
        <footer>
            <div id="footer" className='footer container'>
                <div className='footerAll'>
                    <div className='firstrow'>
                        <div className='logoFooter'><img src="./img/logoFooter.svg  " alt="" /></div>
                        {!!props.data &&
                            props.data.map((data, index) => {
                                return (
                                    <div className='address' key={index}>
                                        {/* <div className='iconFooter'><img src={`./img/${data.icon}`} alt="" /></div> */}

                                        <div className='infoFooter'>
                                            {
                                                data.linkEmail ?
                                                    <>
                                                        <span>{`${t(data.title1)}: `}</span>  <a href={`mailto:${data.title}`}>{data.title} </a>
                                                    </> :
                                                    data.tel ?
                                                        <><span>{`${t(data.title1)}: `}</span><a href={`tel:${data.tel}`}>{data.title} </a> </>
                                                        :
                                                        <><span>{t(data.title1)}: </span>{data.title}</>
                                            }

                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className='secondrow'>
                        <div className='abuFooter'>
                            <div className='nameFooter'>{t('aboutUs')}</div>
                            {!!props.data2 &&
                                props.data2.map((data, index) => {
                                    return (
                                        <div key={index} className='abuContent'><a href={`#${data.href}`}>{t(data.title)}</a></div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className='thirdrow'>
                        <div className='resourceFooter'>
                            <div className='nameFooter'>{t('subscribeToOurNewsletter')}</div>
                            <div className='abuEmail'>
                                <input type="text" placeholder={t('enterEmail')} />
                                <button className='btnSubmitFooter'>{t('submit')}</button>
                                <div className='socialMedia'>
                                    <a href="#"><img src="./img/facebook.png" alt="" /></a>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </footer>
    );
}

export default Footer;