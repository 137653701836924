import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import './aboutus.css'
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

function Aboutus(props) {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    const { t, i18n } = useTranslation();
    const options = {
        margin: 20,
        responsiveClass: true,
        dots: true,
        autoplay: true,
        nav: true,
        navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
        rewindNav: true,
        loop: true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 2,
            }
        },
    };

    return (
        <div className='aboutus ' id='aboutus'>
            <div className='container'>
                <section className='abu'>
                    <div className='abuTitle' data-aos="fade-up" data-aos-once='true'>{t('founderCoFounderSaying')}</div>
                    <div className='redline'></div>
                    <div className='sbuSlicker'>


                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            breakpoints={{
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 15
                                },

                            }}
                        >
                            {!!props.data &&
                                props.data.map((data, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div key={index} className='abuItem' data-aos="fade-up" data-aos-once='true'>
                                                <div className='abuRedTick'>“</div>
                                                <div className='abuRight'>
                                                    {/* <div className='abuStar'><img src="./img/star.svg" alt="" /></div> */}
                                                    <div className='abuInfo'>{t(data.title)}</div>
                                                    <div className='abuPerson'>
                                                        <div className='abuFace'><img src={`./img/${data.img}`} alt="" /></div>
                                                        <div className='abuPersonInfo'>
                                                            <div className='abuName'>{data.founderName}</div>
                                                            <p>{t(data.position)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </div>
                </section>
            </div>

        </div>
    );
}

export default Aboutus;