import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './service.css';
import { useTranslation } from 'react-i18next';
function Service(props) {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <div className="ourService" id="ourService">
            <section className="service container">
                <div className="serviceTitle" data-aos="fade-up" data-aos-once='true'>
                    <div>{t('projectImplementationServices')}</div>
                    <div className='redsig'></div>
                </div>
                <div className="serviceItemAll">
                    {!!props.data &&
                        props.data.map((data, index) => {
                            return (
                                <div key={index} className="serviceItem" data-aos={`fade-${data.animation}`} data-aos-once='true'>
                                    <div className="serviceIcon">
                                        <img src={`./img/${data.icon}`} alt="" />
                                        <div className="serviceItemTitle">{t(data.title)}</div>
                                        <img className={`starIconService${index} star2`} src='./img/star2.svg' />
                                    </div>
                                    <div className="serviceInfo">{t(data.info)}</div>
                                    <div className="serviceInfo">{t(data.info2)}</div>
                                    <ul className={`serviceNone${index}`}>
                                        {!!props.data2 &&
                                            props.data2.map((data, index) => {
                                                return (
                                                    <li key={index}>{t(data.info)}</li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            );
                        })}
                </div>
            </section>
        </div>

    );
}

export default Service;