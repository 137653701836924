import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import './sliderLogo.css';
import { useTranslation } from 'react-i18next';

function SliderLogo(props) {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const { t } = useTranslation();
  const options = {
    responsiveClass: true,
    dots: true,
    autoPlay: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      }
    },
  };
  return (
    <div className='ourCilent container' id="ourCilent">
      <div className='titleCilent row' data-aos="fade-up" data-aos-once='true'>{t('ourClients')}</div>
      <div className="redsig"></div>
      <div className='sliderCilent row'>
        <OwlCarousel className='imgAll' {...options}>
          {!!props.data &&
            props.data.map((data, index) => {
              return (
                <div key={index} className='imgCilent' data-aos="fade-up" data-aos-delay={data.time} data-aos-once='true'>
                  <div className='img1'>
                    <img className="imgLogo1" src={`./img/${data.img}`} />
                  </div>
                  <div className='img2'>
                    <img className="imgLogo2" src={`./img/${data.img1}`} />
                  </div>
                </div>
              );
            })}
        </OwlCarousel>
      </div>
    </div>
  );
}

export default SliderLogo;

